import NavbarTop from './NavbarTop';
import Aside from './Aside'
import Section from './Section'
import Footer from './Footer'
import '../scss/layout.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import {useState, useRef, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
    //GET THEME FROM LOCAL STORAGE 
    const getThemeFromLocalStorage = () => {
        return JSON.parse(localStorage.getItem("theme")) || "light";
    }

    const [theme, setTheme] =useState(getThemeFromLocalStorage());
    //SET THEME TO LOCAL STORAGE
    useEffect(()=> {
        localStorage.setItem("theme", JSON.stringify(theme))
    },[theme])

    //REF HOOK FOR SMOOTH SCROLL
    const refSection = useRef(null);
    const toggleTheme = () => {
        setTheme((current) =>(current === 'light' ? 'dark' : 'light'))
    }

  return (
    <div id={theme} className='layoutWrapper'>
        <div className='layoutContainer'>
            {/* NAVBAR SECTION */}
            {/* Ref Prop For Scrolling */}
            <NavbarTop theme={theme} toggleTheme={toggleTheme} refSection={refSection}/>
            {/* Alert Tag from Toastify (ToastContainer) */}
            <ToastContainer/>
            {/* CONTENT SECTION */}
            <div id={theme} className='content col-10 col-sm-10 col-md-10 col-lg-10 m-auto'>
                {/* contentSizeConfiguration */}
                <Container className='contentContainer'>
                    <Row>
                        {/* ASIDE SECTION -General Informations  */}
                        {/* Ref Prop For Scrolling */}
                        <Aside className='aside'/>
                        {/* OUTLET SECTION */}
                        <Section refSection={refSection}/>
                    </Row>
                </Container>
            </div>
        </div>
        {/* FOOTER SECTION */}
        <Footer id={theme}/>
    </div>
  )
}

export default Layout
