import { redirect } from "react-router-dom";
import { useRef } from "react";
import { Form, Card, Row, Col } from 'react-bootstrap'
import Animation from "./Animation"
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";

export async function action () {
  return redirect(`/about`)
}
export default function Contact() {
  const form = useRef();

  //Successfull Alert from Toastify!
  const notifySubmit = () => 
  toast.success("Form Sent Successfully!", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

    //Error Alert from Toastify!
  const notifyError = () => 
  toast.error("Ooops...! Form Could Not Submitted!", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

  //EmailJS Send Method!
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xwv8dn4', 'template_9a47lzq', form.current, '08lUwN-7jCbLtzBxK')
      .then((result) => {
          form.current.querySelectorAll('.form-control').forEach(item => item.value = "")
          result.text === "OK" && notifySubmit();
        }, (error) => {
          notifyError();
      });
  };

  return (
    <Animation>
      <div id='contact'>
        <Card className="card-contact">
          <Card.Header><h5>GET IN TOUCH</h5></Card.Header>
          <Card.Body className="card-body">
            <Form ref={form} onSubmit={sendEmail}>
              <Form.Group className="contact-form">
                <Row className="mb-3">
                  <Col>
                      <Form.Control name="user_name" type="text" className="form-control "
                          placeholder="Your Name*" aria-label="First name"
                          maxLength="50" required/>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                      <Form.Control name="user_email" type="email" className="form-control "
                          id="email" placeholder="Your E-Mail Address*"
                          aria-label="E-Mail" required/>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                      <Form.Control name="user_subject" type="text" className="form-control"
                          placeholder="Subject" aria-label="Subject"
                          maxLength="50" required/>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                      <textarea name="user_textarea" className="form-control "
                          id="textArea" rows="5" aria-label="Text Area"
                          maxLength="500"
                          placeholder="Please write your message here*" required>
                      </textarea>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <Col>
                      <button type="submit" id="submitBtn"
                          className="submit-button btn btn-outline-secondary rounded-0 btn-md"><strong>Send
                              Message</strong>
                      </button>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Animation>
  );
}