import { Image, Card, ListGroup} from 'react-bootstrap';
import atwork from '../static/img/atwork.jpg'

const Aside = () => {
  return (
    //col-12 col-sm-12 col-md-12
    <aside id="asideDetail" className='col-lg-4'>
        <Card>
            <Card.Header>
                <h4>ABDULLAH SEFA KELES</h4>
            </Card.Header>
            <Card.Body className='left-layout'>
                {/* Profile Picture */}
                <div className='content-picture'>
                    <Image src={atwork} className="img-thumbnail" alt="image"/>
                </div>            
                {/* Personal Information */}
                <div className="info-list mt-3">
                    <ListGroup className='information-list-group'>
                        <ListGroup.Item className="information-list-group-item">
                            <p><strong>NAME:</strong><span> Abdullah Sefa Keles</span></p>
                        </ListGroup.Item>
                        <ListGroup.Item className="information-list-group-item">
                            <p><strong>LOCATION:</strong><span> London / UK</span></p>
                        </ListGroup.Item>
                        <ListGroup.Item className="information-list-group-item">
                            <p><strong>EXPERIENCE:</strong><span> +3 Years</span></p>
                        </ListGroup.Item>
                        <ListGroup.Item className="information-list-group-item">
                            <p><strong>DEGREE:</strong><span> Bachelor's Degree</span></p>
                        </ListGroup.Item>
                        <ListGroup.Item className="information-list-group-item">
                            <p><strong>CAREER LEVEL:</strong><span> Junior Web Developer</span></p>
                        </ListGroup.Item>
                        <ListGroup.Item className="information-list-group-item">
                            <p><strong>E-MAIL:</strong> <a className="information-contact"
                                    target="_blank" rel="noreferrer" href="mailto:sefakeles.uk@gmail.com">
                                    sefakeles.uk@gmail.com</a>
                            </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="information-list-group-item">
                            <p><strong>PHONE:</strong> <a className="information-contact"
                                    target="_blank" rel="noreferrer" href="tel:(+44)7830376489">(+44)7830376489</a></p>
                        </ListGroup.Item>

                        <ListGroup.Item className="information-list-group-item">
                            <p><strong>WEBSITE:</strong> <a className='information-contact' href="https://sefakeles.uk" rel="noreferrer">sefakeles.uk</a>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </div>
            </Card.Body>
        </Card>
        {/* Social Medias */}
        <Card className='mt-1'>
            <Card.Header >
                <h5>SOCIAL PROFILES</h5>
            </Card.Header>
            <Card.Body className='social-list-body'>
                    <ListGroup horizontal className='social-list-group'>
                        <ListGroup.Item className="social-list-item border-0">
                            <a href="https://www.linkedin.com/in/abdullah-keles-826312250/"
                                target="_blank" rel="noreferrer"><i className="fab fa-linkedin fa-2x"></i></a>
                        </ListGroup.Item>
                        <ListGroup.Item className="social-list-item border-0">
                            <a href="https://www.instagram.com/uksefa"
                                target="_blank" rel="noreferrer"><i className="fab fa-instagram fa-2x"></i></a>
                        </ListGroup.Item>
                        <ListGroup.Item className="social-list-item border-0">
                            <a href="https://www.facebook.com/sefa.keles.165685"
                                target="_blank" rel="noreferrer"> <i className="fab fa-facebook-square fa-2x"></i></a>
                        </ListGroup.Item>
                        <ListGroup.Item className="social-list-item border-0">
                            <a href="https://github.com/Sefa-Keles" 
                                target="_blank" rel="noreferrer"><i className="fab fa-github fa-2x"></i></a>
                        </ListGroup.Item>
                    </ListGroup>
            </Card.Body>
        </Card>
    </aside>
  )
}

export default Aside