import { Image, Card, Row, Col } from 'react-bootstrap'
import Animation from './Animation'
import iu from '../static/img/iu.png'
import lp from '../static/img/learningPeople.png'
import calat from '../static/img/calat.png'
import aws from '../static/img/aws.png'
import udemy from '../static/img/udemy.png'
import ba from '../static/img/bilgeTek.png'
import fb from '../static/img/fatihbilisim.png'
import iienst from '../static/img/iienst.png'
import sg from '../static/img/shaneg.png'

const Education = () => {
  return (
    <Animation>
        <div id='education' className='education'>
            {/* <!--EDUCATION Card--> */}
            <Card className="card-education mb-1">
                <Card.Header><h5>EDUCATIONS</h5></Card.Header>
                <Card.Body>
                    <div className="body-education">
                        {/* <!--First Row--> */}         
                        <Row className="education-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="education-date"><strong>Oct 2011 - Jul
                                            2017</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-education">
                                    <h6>
                                        <strong>
                                            MATHEMATICS
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer" href="https://fen.istanbul.edu.tr/tr/_">
                                            <Image className="logo-image" src={iu}
                                                alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary"><a
                                            href="https://fen.istanbul.edu.tr/tr/_"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            Istanbul University
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>Istanbul/
                                            TURKEY</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    The mission of Istanbul University Faculty of Science,
                                    Turkey's first Faculty of Science, is to train individuals
                                    with professional competence, equipped with analysis and
                                    synthesis skills, to contribute to the country's science and
                                    technology by producing information at a universal level,
                                    and to act as a bridge in the transfer of scientific
                                    information to the public.
                                </p>
                            </Col>
                        </Row>

                        {/* <!--Second Row--> */}
                        <Row className="education-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="education-date"><strong> Jan 2017 - Jun 2017
                                        </strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-education">
                                    <h6>
                                        <strong>
                                            PEDAGOGIC FORMATION
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer"
                                            href="https://www.istanbul.edu.tr/tr/_">
                                            <Image className="logo-image" src={iu}
                                                alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary">
                                        <a href="https://www.istanbul.edu.tr/tr/_"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            Istanbul University
                                        </a>
                                    </p>

                                    <p className="text text-city">
                                        <small>Istanbul/
                                            TURKEY</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    Pedagogical formation is a kind of formation education taken
                                    in order to explain academic definitions in different ways
                                    in a language that can be perceived by individuals who are
                                    open to learning. It is designed for the graduates of that
                                    department to be able to teach after receiving education
                                    from any department in the science and literature faculties
                                    of universities.
                                </p>

                            </Col>
                        </Row>

                        {/* <!--Third Row--> */}
                        <Row className=" education-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="education-date"><strong> 2015 -
                                            Present</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-education">
                                    <h6>
                                        <strong>
                                            ECONOMY
                                        </strong>
                                    </h6>
                                    <hr/>

                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer"
                                            href="https://www.istanbul.edu.tr/tr/_">
                                            <Image className="logo-image" src={iu}
                                                alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary">
                                        <a href="https://www.istanbul.edu.tr/tr/_"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            Istanbul University
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>Istanbul/
                                            Turkey</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    It is to produce knowledge for economic and social analysis
                                    with universal research that reflects different views within
                                    the framework of contemporary principles and institutions,
                                    and to raise individuals with critical and creative thinking
                                    on these foundations.
                                </p>

                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>

            {/* <!--TRAININGS Card--> */}
            <Card className="card-trainings mt-3">
                <Card.Header><h5>TRAININGS - SEMINARS - CERTIFICATES</h5></Card.Header>
                <Card.Body>
                    <div className="body-trainings">

                        {/* <!--T-First Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>Aug 2021 -
                                            Aug 2022</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            FRONTEND DEVELOPER BOOTCAMP
                                        </strong>
                                    </h6>
                                    <hr/>

                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer"
                                            href="https://www.learningpeople.com/uk">
                                            <Image className="logo-image"
                                                src={lp}
                                                alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary">
                                        <a href="https://www.learningpeople.com/uk"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            Learning People
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>Brighton/
                                            UK</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    Our Front End Developer Bootcamp starts with the
                                    fundamentals of programming and moves you through the
                                    vital areas of front end development. Through the
                                    provision of soft skills training, career services,
                                    hands on labs and real world projects we prepare you for
                                    your first role as a Front End Developer.
                                </p>

                            </Col>
                        </Row>

                        {/* <!--T-Second Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>Jan 2022 - Jul
                                            -
                                            2022</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            ENGLISH COURSE (ESOL)
                                        </strong>
                                    </h6>
                                    <hr/>

                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer" href="https://www.calat.ac.uk/">
                                            <Image className="logo-image"
                                                src={calat} alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary">
                                        <a href="https://www.calat.ac.uk/"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            CALAT
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>London/
                                            UK</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    These courses are for people who are settled in the UK
                                    to gain English language skills and work towards
                                    nationally recognised qualifications.
                                    Our qualified teachers will help you with your English
                                    for everyday life. You will study in a friendly, safe
                                    environment, meet new friends, gain confidence and learn
                                    employability skills.
                                    We can help you get the most out of living in the UK.
                                    Improving your English can help you continue your
                                    studies at college or university, improve your job
                                    prospects, or move on to a vocational course.
                                </p>

                            </Col>
                        </Row>

                        {/* <!--T-Third Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>Sep
                                            2021</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            AWS WEBINARS CERTIFICATE
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer"
                                            href="https://aws.amazon.com/events/?nc2=h_ql_ev_ev&refid=ce1f55b8-6da8-4aa2-af36-3f11e9a449ae">
                                            <Image className="logo-image"
                                                src={aws} alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary"><a
                                            href="https://aws.amazon.com/events/?nc2=h_ql_ev_ev&refid=ce1f55b8-6da8-4aa2-af36-3f11e9a449ae"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            AWS Training Webinars
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>London/
                                            UK</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    AWS Training and Certification hosts free training
                                    events, both online and in person, that help the
                                    builders of today and tomorrow leverage the power of the
                                    AWS Cloud. Whether you’re building foundational cloud
                                    knowledge or diving deep in a technical area, join AWS
                                    experts for a training event that meets your goals.
                                </p>
                            </Col>
                        </Row>

                        {/* <!--T-Fourth Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>
                                            2018</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            REACTJS - CONTEXT API & Redux
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer" href="https://www.udemy.com/">
                                            <Image className="logo-image"
                                                src={udemy} alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary"><a
                                            href="https://www.udemy.com/"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            Udemy Online Course
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>London/
                                            UK</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    I have always aimed to provide courses that exceed
                                    expectations. That means you won't just be learning
                                    React and Redux. I also talked about technologies that I
                                    believe can work integrated with these technologies or
                                    that you can do better when you use them together.
                                </p>
                            </Col>
                        </Row>

                        {/* <!--T-Fifth Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>
                                            2018</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            JAVASCRIPT & NODEJS
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer" href="https://www.udemy.com/">
                                            <Image className="logo-image"
                                                src={udemy} alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary"><a
                                            href="https://www.udemy.com/"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            Udemy Online Course
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>London/
                                            UK</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    After saying what is Node.JS in this set, the topic will
                                    not be closed with a couple of examples. So our topic is
                                    not just Node.JS. For example, if you can't scale the
                                    Node application you wrote, it doesn't make any sense.
                                    For this, we will talk about "Load Balancing". What
                                    should I do after I can't automate the test and deploy
                                    processes? So we will be talking about "Continuous
                                    Integration" and with it "Travis CI". In other words,
                                    while developing a Node project, we will also talk about
                                    what systems we need.
                                </p>
                            </Col>
                        </Row>

                        {/* <!--T-Sixth Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>
                                            2018</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            MODERN JAVASCRIPT & TYPESCRIPT
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer" href="https://www.udemy.com/">
                                            <Image className="logo-image"
                                                src={udemy} alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary"><a
                                            href="https://www.udemy.com/"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            Udemy Online Course
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>London/
                                            UK</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    Welcome to our Modern Javascript course, which will
                                    prepare you for the world of Javascript and teach you
                                    the basics of modern javascript techniques (ES6/ES7+).

                                    Javascript, which is more or less known to everyone who
                                    is interested in web development, has developed itself
                                    with modern javascript techniques (ES6 & ES7+) since
                                    2015, and at this point, every software developer must
                                    have a good knowledge of Modern Javascript. Because the
                                    usage area of Javascript has increased considerably.
                                    Having a solid knowledge of modern Javascript is the
                                    most important step we have to take on behalf of
                                    software lately. In our Modern Javascript course, we
                                    will learn modern javascript techniques with javascript
                                    lessons and create a good foundation for nodejs,
                                    angular, react and vuejs.
                                </p>
                            </Col>
                        </Row>

                        {/* <!--T-Seventh Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>2017 -
                                            2018</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            .NET PROGRAMMING (+250 Hours)
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer"
                                            href="https://akademi.bilgeadam.com/">
                                            <Image className="logo-image"
                                                src={ba} alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary"><a
                                            href="https://akademi.bilgeadam.com/"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            Bilge Adam Academy
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>Istanbul/
                                            TURKEY</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    It is for participants who want to develop applications
                                    by learning C# programming language and programming
                                    basics and object-oriented programming (OOP) structure,
                                    database and data access techniques, client-based and
                                    server-based application development, Open Source
                                    technology .NET Core MVC and PostgreSQL database
                                    structures.
                                </p>
                            </Col>
                        </Row>

                        {/* <!--T-Eighth Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>2017 -
                                            2018</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            WEB PROGRAMMING & ADAPTATION TRAINING WITH
                                            ASP.NET MVC
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer"
                                            href="https://enstitu.ibb.istanbul/portal/kurs_icerik.aspx?KursMerkezi=69">
                                            <Image className="logo-image"
                                                src={fb}
                                                alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary"><a
                                            href="https://enstitu.ibb.istanbul/portal/kurs_icerik.aspx?KursMerkezi=69"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            FATIH TECHNOLOGY COURSE ISMEK
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>Istanbul/
                                            TURKEY</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    This program aims to train people who can develop web
                                    applications in line with today's needs, prepare dynamic
                                    websites that can be updated, by teaching the
                                    participants programming and database logic. At the same
                                    time, it aims to examine basic and advanced ASP.NET
                                    topics and components, and to teach database operations.
                                    The language that will be used throughout the ASP.NET
                                    training is C#.
                                </p>
                            </Col>
                        </Row>

                        {/* <!--T-Ninth Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>2017 -
                                            2018</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            COMPUTER MANAGEMENT TRAINING
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer"
                                            href="https://enstitu.ibb.istanbul/portal/kurs_icerik.aspx?KursMerkezi=66">
                                            <Image className="logo-image"
                                                src={fb}
                                                alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary"><a
                                            href="https://enstitu.ibb.istanbul/portal/kurs_icerik.aspx?KursMerkezi=66"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            FATIH TECHNOLOGY COURSE ISMEK
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>Istanbul/
                                            TURKEY</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    This program; It aims to train people who know computer
                                    hardware by using the basic concepts of computer
                                    technology, can use operating system components and
                                    office programs, can do research on the internet and
                                    have information about information security.
                                </p>
                            </Col>
                        </Row>

                        {/* <!--T-Tenth Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>
                                            2016</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            FINANCIAL MANAGEMENT
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_blank" rel="noreferrer"
                                            href="https://www.iienstitu.com/">
                                            <Image className="logo-image"
                                                src={iienst} alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary"><a
                                            href="https://www.iienstitu.com/"
                                            className="organisation-link" target="_blank" rel="noreferrer">
                                            IIENSTITU
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>Istanbul/
                                            TURKEY</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    The concept of financial management, on the other hand,
                                    means finding the funds that the business needs in order
                                    to achieve the goals it has set and using the funds in a
                                    way that can serve the purpose of the business. It is
                                    the concept that represents a business's reaching its
                                    financial goals and the financing it needs. Today,
                                    businesses aim to increase the value of the business by
                                    maximizing the level of profit making while making
                                    financial decisions. This is where the concept of
                                    financial management comes into play. In other words,
                                    this concept refers to the best and effective management
                                    of financial affairs in order for businesses to reach
                                    their financial goals. The biggest goal of financial
                                    management is to try to maximize the value of an
                                    organization or business. Therefore, determining the
                                    capital needed by the organization or business ensures
                                    that the relevant funds are beneficial to the business
                                    by meeting the relevant needs.
                                </p>
                            </Col>
                        </Row>

                        {/* <!--T-Eleventh Row--> */}
                        <Row className="training-row mb-5">
                            <Col className="col-sm-4">
                                <div className="date">
                                    <span className="trainings-date"><strong>
                                            2011</strong></span>
                                </div>
                            </Col>
                            <Col className="col-sm-8 col-12">
                                <div className="content-trainings">
                                    <h6>
                                        <strong>
                                            ENGLISH COURSE
                                        </strong>
                                    </h6>
                                    <hr/>
                                    <div className="logo ml-auto">
                                        <a target="_self" href="/#">
                                            <Image className="logo-image"
                                                src={sg} alt="logo"/>
                                        </a>
                                    </div>
                                    <p className="text text-primary"><a href="/#"
                                            className="organisation-link" target="_self">
                                            Shane Global Language Center
                                        </a>
                                    </p>
                                    <p className="text text-city">
                                        <small>7 Warrior Square<br/>
                                        St Leonards On Sea Hastings TN37 6BA <br/>Hastings/UK</small>
                                    </p>
                                </div>
                                <p className="organization-text">
                                    Shane Global Language Centres is part of the Saxoncourt Group of companies, one of the worlds leading language training organisations. Shane Global Language Centres Hastings is accredited by the British Council and a member of English Uk.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        </div>
    </Animation>
  )
}

export default Education