import { Card, Col, Row } from 'react-bootstrap';
import Animation from './Animation';

const About = () => {
  return (
    <Animation>
        <div id='about' className='about'>
            {/* ABOUT SECTION*/}
            <Card className="card-about">
                <Card.Header><h5>ABOUT ME</h5></Card.Header>
                <Card.Body>
                    <p className="body-about">
                        Junior Frontend Web Developer with particularly strong Front-End [HTML, CSS, SASS-SCSS, Bootstrap, JS, ReactJS] skills. Exposure to Back-End Development using [C#, ASP.Net MVC 4, NodeJS] with OOP and N-Tier architectural approach. Can perform basic CRUD operations on the database. Thinks analytically as a Mathematician. Develops software by adopting clean code techniques. Possesses WordPress experience from KLS Global Web Solutions / for 2 years and ReactJS, Spring Boots from TTG International / 1-year experience. Currently seeking a new opportunity as a Front-End Developer within the IT sector where I can showcase my existing skills and progress further. Details of personal projects available on my Github: <a href='https://github.com/Sefa-Keles' target="_blank" rel="noreferrer">https://github.com/Sefa-Keles</a>
                    </p>
                </Card.Body>
            </Card>
            {/* CORE COMPETENCIES */}
            <Card className="mt-3">
                <Card.Header><h5>CORE COMPETENCIES</h5></Card.Header>
                <Card.Body>
                    <div className="body-competencies">
                        <Row className="p-2 mt-2 mb-2">
                            <Col
                                className="col-competencies col-12 col-sm-12 col-lg-4 px-2 text-center">
                                    <div className="competencies-icon">
                                        <i className="fas fa-hourglass-half fa-2x"></i>
                                        <h6>TIME MANAGEMENT</h6>
                                    </div>
                                    <div className="competencies-info">
                                        Well organized, detail-oriented, ability to multi-task,
                                        excellent time-management, planning and prioritization
                                    </div>
                            </Col>
                            <Col
                                className="col-competencies col-12 col-sm-12 col-lg-4 px-2 text-center">
                                    <div className="competencies-icon">
                                        <i className="fas fa-magic fa-2x"></i>
                                        <h6>ANALYTICAL THINKING</h6>
                                    </div>
                                    <div className="competencies-info">
                                        Ability to assimilate, collate, analyze complex data in
                                        projects and process data into meaningful results
                                    </div>
                            </Col>
                            <Col
                                className="col-competencies col-12 col-sm-12 col-lg-4 px-2 text-center">
                                    <div className="competencies-icon">
                                        <i className="fas fa-chart-line fa-2x"></i>
                                        <h6>INITIATIVE</h6>
                                    </div>
                                    <div className="competencies-info">
                                        Forward-thinking; constantly striving to develop new
                                        solutions to improve application efficiency.
                                    </div>
                            </Col>
                            <Col
                                className="col-competencies col-12 col-sm-12 col-lg-4 px-2 text-center">
                                    <div className="competencies-icon">
                                        <i className="far fa-smile fa-2x"></i>
                                        <h6>COMMUNICATION</h6>
                                    </div>
                                    <div className="competencies-info">
                                        Liaises effectively and
                                        efficiently across multiple levels of the organisation;
                                        confident presenter able to engage with recipients in
                                        order to impart information and deliver projects on
                                        time.
                                    </div>
                            </Col>
                            <Col
                                className="col-competencies col-12 col-sm-12 col-lg-4 px-2 text-center">
                                    <div className="competencies-icon">
                                        <i className="fas fa-server fa-2x"></i>
                                        <h6>TECHNICAL KNOWLEDGE</h6>
                                    </div>
                                    <div className="competencies-info">
                                        With the quality trainings he attended, he transformed
                                        the most up-to-date technologies into technical skills
                                        and reflected them in his projects.
                                    </div>
                            </Col>
                            <Col
                                className="col-competencies col-12 col-sm-12 col-lg-4 px-2 text-center">
                                    <div className="competencies-icon">
                                        <i className="fas fa-laptop-code fa-2x"></i>
                                        <h6>TECHNOLOGIES</h6>
                                    </div>
                                    <div className="competencies-info">
                                        Develops web-based applications using the latest
                                        technologies with a clean code understanding. does the
                                        testing and maintenance
                                    </div>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        </div>
    </Animation>
  )
}

export default About