import { motion } from 'framer-motion'

//Animation component that wraps all components
const Animation = ({ children }) => {
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.75, ease:"easeOut"}}>
        {children}
    </motion.div>
  )
}

export default Animation