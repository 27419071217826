import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Layout from './components/Layout';
import Error from './components/Error'
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Hello from './components/Hello';
import About from './components/About';
import Education from './components/Education';
import Experience from './components/Experience';
import Contact from './components/Contact';
import {action as submitAction} from './components/Contact'


//ROUTER SECTION
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    errorElement: <Error/>,
    children: [
      {
        //Pathless Route
        errorElement: <Error/>,
        children: [
          {index: true, element: <Hello/>},
          {
            path: "/about",
            element: <About/>
          },
          {
            path: "/education",
            element: <Education/>
          },
          {
            path: "/experience",
            element: <Experience/>
          },
          {
            path: "/contact",
            element: <Contact/>,
            action: submitAction
          }
        ]
      }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
