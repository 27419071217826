import {  Card } from 'react-bootstrap';
import Marquee from "react-fast-marquee";

const Hello  = () => {
    return (
        <div id="hello">
            <Card>
                <Card.Body>
                    <h2 className="hello-welcome">
                        Hello There_
                    </h2>
                    <div id='hello-title' behavior="scroll" direction="left" scrollamount="7">
                        <Marquee speed={40}>
                            <h3>
                                I Am a Front-End Web Developer
                            </h3>
                        </Marquee>
                    </div>
                    <div id='hello-content' behavior="slide" direction="up">
                        <p>
                            A passionate, driven, and enterprising developer who has had the opportunity to learn and develop on various web projects of varying difficulty levels over his career spanning over <u>3-years</u>. I'm currently running my startup in London and I'm looking forward to seeing new opportunities. Feel free to reach out if you want to <u>collaborate</u> with me or simply have a <u>chat</u>.
                        </p>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Hello