import React from 'react'

const Footer = () => {
  return (
    <footer className='footer'>
        <div className="copyright">
            <p className="text-center">
                © 2022 - Developed by ABDULLAH SEFA KELES. All right reserved.
            </p>
        </div>
    </footer>
  )
}

export default Footer