import { Outlet, useNavigation } from 'react-router-dom'

const Section = ({refSection}) => {
    const navigation = useNavigation();
  return (
    <section ref={refSection} id='sectionDetail' className={`col-lg-8 ${navigation.state === "loading" ? "LOADING" : ""}`}>
        {/* context={[referenceElement]} */}
        <Outlet/>
    </section>
  )
}

export default Section

