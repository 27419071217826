import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import  handleScroll  from './Scroll';
import ReactSwitch from 'react-switch'


const NavbarTop = ({theme, toggleTheme, refSection}) => {

    return (
        <Navbar id="navbar" expand='lg' fixed='top'>
            <Container className='col-12 col-sm-10 col-md-10 col-lg-10 m-auto navbarContainer'> 
            {/* LIGHT-DARK MODE */}
            <div className="switch">
                <span className='badge'>{theme === 'light'?'Light Mode': 'Dark Mode'}</span>
                <ReactSwitch onChange={toggleTheme} checked={theme === 'dark'}/>
            </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className='navbarCollapse'>
                    <Nav id='nav' className="nav">
                        <NavLink onClick={()=> {handleScroll(refSection.current)}} className='navlink' to={`/about`}>
                            <i className="fas fa-user-tie"></i>
                            <span className='m-2'>ABOUT ME</span>
                        </NavLink>
                        <NavLink onClick={()=> {handleScroll(refSection.current)}} className='navlink' to={`/education`}>
                            <i className="fa fa-graduation-cap"></i>
                            <span className='m-2'>EDUCATIONS & TRAININGS</span>
                        </NavLink>
                        <NavLink onClick={()=> {handleScroll(refSection.current)}} className='navlink' to={`/experience`}>
                            <i className="fas fa-folder-open"></i>
                            <span className='m-2'>PROFESSIONAL EXPERIENCE</span>
                        </NavLink>
                        <NavLink onClick={()=> {handleScroll(refSection.current)}} className='navlink' to={`/contact`}>
                            <i className="fas fa-comments"></i>
                            <span className='m-2'>CONTACT</span>
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )

}
export default NavbarTop