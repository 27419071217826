
const handleScroll = (ref) => {
    window.scrollTo({
        top: ref.offsetTop-340,
        left: 0,
        behavior: "smooth",

    });
}

export default handleScroll