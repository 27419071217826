import {Image, Card, Row, Col, ListGroup} from 'react-bootstrap'
//import { useOutletContext } from "react-router-dom";
import Animation from './Animation'
import kls from '../static/img/kls.png'
import ttg from '../static/img/ttg.png'

const Experience = () => {
    //const [referenceElement] = useOutletContext();
    //ref={referenceElement.refExperience}
  return (
    <Animation >
        <div id='experience' className='experience'>
            <Card>
                <Card.Header><h5>CAREER HISTORY & ACCOMPLISHMENTS</h5></Card.Header>
                <Card.Body>
                {/* Presentation */}
                <div className="body-experience">
                    <Row className="experience-row mb-5">

                    {/* First Experience */}
                        <Col className="col-sm-4 ">
                            <div className="date">
                                <span className="experience-date">
                                    <strong>Jan 2020 - Present</strong>
                                </span>
                            </div>
                        </Col>
                        <Col className="col-sm-8 col-12">
                            <div className="content-experience">
                                <h6>
                                    <strong>
                                        FRONTEND DEVELOPER
                                    </strong>
                                </h6>
                                <hr/>
                                <div className="logo ml-auto">
                                    <a target="_blank" rel="noreferrer"
                                        href="https://www.klsglobalwebsolutions.co.uk/">
                                        <Image className="logo-image" src={kls}
                                            alt="logo"/>
                                    </a>
                                </div>
                                <p className="text text-primary"><a
                                        href="https://www.klsglobalwebsolutions.co.uk/"
                                        className="organisation-link" target="_blank" rel="noreferrer">
                                        KLS Global Web Solutions LTD.
                                    </a>
                                </p>
                                <p className="text text-city">
                                    <small>London/
                                        UK</small>
                                </p>
                            </div>
                            <p className="organization-text">
                                KLS Global Web Solutions LTD, which was put into service in
                                2020 and which I am the manager of, is a technology
                                initiative that provides service and technological solutions
                                in the food, shopping and trade sectors in the UK.
                            </p>
                            {/* <!--Responsibilities--> */}
                            <p className="exp-history margin-top-10"
                                    style={{borderBottom: "1px solid #e0e0e0"}}>
                                <strong>Responsibilities:</strong>
                            </p>
                            <ListGroup className="margin-top-10 experience-list-group">
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Turning business
                                        problems into technical design and translating
                                        business requirements into development </p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Providing
                                        recommendations for improvements to the product,
                                        working closely with the product owner</p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>To assess current
                                        systems’ architecture and provide required design
                                        and development of new technology solutions</p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Enhancing and
                                        enforcing procedures and processes (Code
                                        reviews/tests) and making sure these are applied</p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Review requirements
                                        to design, code, test and document complex tasks
                                        using the right standards and tools</p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Providing
                                        development estimates for the delivery of the tasks
                                    </p>
                                </ListGroup.Item>
                            </ListGroup>
                            {/* <!--Key Achievements--> */}
                            <p className="exp-history margin-top-10"
                                style={{borderBottom: "1px solid #e0e0e0"}}>
                                <strong>Key Achievements:</strong>
                            </p>
                            <ListGroup className="margin-top-10 experience-list-group">
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Developing Web
                                        applications with the powerful ReactJS framework or
                                        WordPress</p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Domain and Hosting
                                        operations</p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>SEO optimizations
                                    </p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Provide software
                                        testing, maintenance and update processes</p>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        
                        {/* <!--Second Experience--> */}
                        <Col className="col-sm-4 mt-5">
                            <div className="date">
                                <span className="experience-date"><strong>Nov 2018 - Nov
                                        2019</strong></span>
                            </div>
                        </Col>
                        <Col className="col-sm-8 col-12">
                            <div className="content-experience">
                                <h6>
                                    <strong>
                                        JUNIOR FRONTEND DEVELOPER
                                    </strong>
                                </h6>
                                <hr/>
                                <div className="logo ml-auto">
                                    <a target="_blank" rel="noreferrer" href="https://www.ttgint.com/">
                                        <Image className="logo-image" src={ttg}
                                            alt="logo"/>
                                    </a>
                                </div>

                                <p className="text text-primary"><a
                                        href="https://www.ttgint.com/"
                                        className="organisation-link" target="_blank" rel="noreferrer">
                                        TTG International R&D Center LTD.
                                    </a>
                                </p>
                                <p className="text text-city">
                                    <small>Istanbul/
                                        TURKEY</small>
                                </p>
                            </div>
                                <p className="organization-text">
                                    Since its establishment in 2001, TTG International LTD has
                                    been producing best-in-class Operations Support System (OSS)
                                    software solutions for telecommunication, IT and Smart grid
                                    industry. With TTG's proactive and customer-oriented
                                    approach, it provides innovative OSS software and services
                                    as well as R&D (e.g., 5G OSS/Element Management Systems,
                                    etc.) to address the challenges, needs, and automation with
                                    latest software techniques, AI/ML technologies to enable our
                                    customers to achieve maximum value for their businesses.
                                </p>
                                {/* <!--Responsibilities--> */}
                                <p className="exp-history margin-top-10"
                                    style={{borderBottom: "1px solid #e0e0e0"}}>
                                    <strong>Responsibilities:</strong>
                                </p>
                                <ListGroup className="margin-top-10 experience-list-group">
                                    <ListGroup.Item className='experience-list-item'>
                                        <p><i className="fa fa-cog fa-spin"></i>Finding and fixing
                                            the bugs in the projects</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='experience-list-item'>
                                        <p><i className="fa fa-cog fa-spin"></i>Ensuring the
                                            development of modules within different projects
                                        </p>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='experience-list-item'>
                                        <p><i className="fa fa-cog fa-spin"></i>Creation of some
                                            pages within different projects</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='experience-list-item'>
                                        <p><i className="fa fa-cog fa-spin"></i>Completing other
                                            tasks determined through Gitlab in order</p>
                                    </ListGroup.Item>
                                </ListGroup>
                                {/* <!--Key Achievements--> */}
                                <p className="exp-history margin-top-10"
                                    style={{borderBottom: "1px solid #e0e0e0"}}>
                                    <strong>Key Achievements:</strong>
                                </p>
                                <ListGroup className="margin-top-10 experience-list-group ">
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Development of
                                        debugging capabilities</p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Working with
                                        complex KPIs</p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Turning business
                                        problems into technical design and translating
                                        business requirements into development tasks</p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Understanding the
                                        life-cycle between frontend and backend in a large
                                        project </p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Development of
                                        research and observation skills</p>
                                </ListGroup.Item>
                                <ListGroup.Item className='experience-list-item'>
                                    <p><i className="fa fa-cog fa-spin"></i>Adapting to the
                                        project by learning new technologies</p>
                                </ListGroup.Item>
                                </ListGroup>
                        </Col>
                    </Row>
                </div>
                </Card.Body>
            </Card>
        </div>
    </Animation>
  )
}

export default Experience