import { useRouteError } from 'react-router-dom'

const Error = () => {
    const error = useRouteError();
  return (
    <div>
        <h2>OPS! Something Wrong.</h2>
        <p>
            <i> {error.statusText}</i>
            <i>{ error.status}</i>
        </p>
    </div>
  )
}

export default Error